import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

const App = lazy(() => import('./App'));
const LoadingComponent = lazy(() => import('./components/LoadingComponent'));
const AuthProvider = lazy(() => import('./contexts/AuthContext').then(module => ({ 
  default: module.AuthProvider 
})));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);